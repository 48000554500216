import DragableIcon from "@rsuite/icons/Dragable";
import ChangeListIcon from "@rsuite/icons/ChangeList";
import DashboardIcon from "@rsuite/icons/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import SiteFillIcon from "@rsuite/icons/SiteFill";
import GridIcon from "@rsuite/icons/Grid";
import TagIcon from "@rsuite/icons/Tag";
import { IoBriefcaseOutline } from "react-icons/io5";
import { FaRegBell } from "react-icons/fa";

export const nav = [
  {
    title: "Dashboard",
    link: "/",
    icon: <DashboardIcon />,
  },
  {
    title: "Home Page Master",
    icon: <GridIcon />,
    menu: [
      {
        title: "Home Slider",
        link: "/home-slider",
      },
      {
        title: "Logo",
        link: "/getmii-logo",
      },
      {
        title: "Getmii Service",
        link: "/getmii-services",
      },
    ],
  },
  {
    title: "Category Master",
    icon: <TagIcon />,
    menu: [
      {
        title: "Shop Category",
        link: "/shop-category",
      },
      // {
      //   title: "Shop Category Slider",
      //   link: "/shop-category-slider",
      // },
      {
        title: "Product Category",
        link: "/shop-product-category",
      },
      // {
      //   title: "Product Category Slider",
      //   link: "/shop-product-category-slider",
      // },
    ],
  },
  {
    title: "Shop Master",
    icon: <TagIcon />,
    menu: [
      {
        title: "Registered Shops",
        link: "/registered-shops",
      },
    ],
  },
  {
    title: "Product Master",
    icon: <TagIcon />,
    menu: [
      {
        title: "Products",
        link: "/products",
      },
    ],
  },
  {
    title: "Campaign",
    icon: <TagIcon />,
    menu: [
      {
        title: "Campaign",
        link: "/campaign",
      },
    ],
  },
  {
    title: "Orders Master",
    icon: <TagIcon />,
    menu: [
      {
        title: "Orders",
        link: "/orders",
        icon: <ChangeListIcon />,
      },
    ],
  },
  {
    title: "Wallet Master",
    icon: <SiteFillIcon />,
    menu: [
      {
        title: "Vendor Wallet Slider",
        link: "/vendor-wallet-slider",
        icon: <ChangeListIcon />,
      },
      {
        title: "User Wallet Slider",
        link: "/user-wallet-slider",
        icon: <ChangeListIcon />,
      },
    ],
  },
  {
    title: "Users",
    link: "/users",
    icon: <GroupIcon />,
  },
  {
    title: "Demonstration",
    link: "/demonstration-list",
    icon: <GridIcon />,
  },
  {
    title: "Product Demand",
    link: "/demands",
    icon: <GridIcon />,
  },
  {
    title: "City Job Master",
    icon: <IoBriefcaseOutline className="rs-sidenav-item-icon rs-icon" />,
    menu: [
      {
        title: "City Jobs ",
        link: "/city-jobs",
      },
      {
        title: "City Jobs Industry",
        link: "/city-jobs-industries",
      },
    ],
  },
  {
    title: "Payments",
    icon: <IoBriefcaseOutline className="rs-sidenav-item-icon rs-icon" />,
    menu: [
      {
        title: "Subscription Payments",
        link: "/subscription-payments",
      },
    ],
  },
  {
    title: "App Notifications",
    link: "/app-notification",
    icon: <FaRegBell className="rs-sidenav-item-icon rs-icon" />,
  },
  {
    title: "Shop User Guide",
    link: "/shop-user-guide",
    icon: <FaRegBell className="rs-sidenav-item-icon rs-icon" />,
  },
];
