import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useCookies } from "./useCookies";
import Cookies from "js-cookie";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [cookie, setCookie] = useCookies("token", null);
  const [mobile, setMobile] = useState(false);
  const navigate = useNavigate();

  const login = async (data) => {
    setUser(data);
    setCookie(data.token);
    navigate("/", { replace: true });
  };

  const logout = () => {
    setUser(null);
    setCookie(null);
    navigate("/login", { replace: true });
  };

  const handleResize = () => {
    if (window.innerWidth < 920) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  // create an event listener
  useEffect(() => {
    if (window.innerWidth < 920) {
      setMobile(true);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
      mobile,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
