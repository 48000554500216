import { lazy } from "react";

const Dashboard = lazy(() => import("./pages/Dashboard"));
const HomeSlider = lazy(() => import("./pages/homeslider/HomeSlider"));
const GetmiiServices = lazy(() =>
  import("./pages/getmiiservices/GetmiiServices")
);
const GetmiiServiceSlider = lazy(() =>
  import("./pages/getmiiservices/GetmiiServiceSlider")
);
const GetmiiLogo = lazy(() => import("./pages/getmiilogo/GetmiiLogo"));
const ShopCategory = lazy(() => import("./pages/shopcategory/ShopCategory"));
const ShopCategorySlider = lazy(() =>
  import("./pages/shopcategoryslider/ShopCategorySlider")
);
const ProductCategory = lazy(() =>
  import("./pages/productcategory/ProductCategory")
);
const ProductCategorySlider = lazy(() =>
  import("./pages/productcategory/ProductCategorySlider")
);
const ProductCategorySpecs = lazy(() =>
  import("./pages/productcategory/ProductCategorySpecs")
);
const RegisteredShop = lazy(() =>
  import("./pages/shopregistration/RegisteredShop")
);
const ShopDetails = lazy(() => import("./pages/shopregistration/ShopDetails"));
const Products = lazy(() => import("./pages/product/Products"));
const VariantSpecs = lazy(() => import("./pages/productcategory/VariantSpecs"));
const ViewProduct = lazy(() => import("./pages/product/ViewProduct"));
const Orders = lazy(() => import("./pages/orders/Orders"));
const OrderDetails = lazy(() => import("./pages/orders/OrderDetails"));
const Users = lazy(() => import("./pages/users/Users"));
const UserDetails = lazy(() => import("./pages/users/UserDetails"));
const Campaign = lazy(() => import("./pages/campaign/Campaign"));
const CampaignItem = lazy(() => import("./pages/campaign/CampaignItem"));
const DemoList = lazy(() => import("./pages/sell_on_getmii_demo/DemoList"));
const Demands = lazy(() => import("./pages/demand/Demands"));
const DemandDetails = lazy(() => import("./pages/demand/DemandDetails"));
const WalletSlider = lazy(() => import("./pages/wallet/WalletSlider"));
const Jobs = lazy(() => import("./pages/jobs/Jobs"));
const JobIndustries = lazy(() => import("./pages/jobs/JobIndustries"));
const JobDetails = lazy(() => import("./pages/jobs/JobDetails"));
const SubsciptionPayments = lazy(() =>
  import("./pages/payments/SubsciptionPayments")
);
const Notifications = lazy(() =>
  import("./pages/notificationMaster/Notifications")
);
const UserWalletSlider = lazy(() =>
  import("./pages/userwallet/UserWalletSlider")
);
const UserGuideList = lazy(() => import("./pages/shopUserGuide/UserGuideList"));

const routes = [
  { path: "/", exact: true, name: "Dashboard", element: Dashboard },
  { path: "/users", name: "Users", element: Users },
  { path: "/users/:id", name: "Users", element: UserDetails },
  //HOME PAGE ROUTES
  { path: "/home-slider", name: "Home Slider", element: HomeSlider },
  {
    path: "/getmii-services",
    name: "Getmii Services",
    element: GetmiiServices,
  },
  {
    path: "/getmii-service-slider",
    name: "Getmii Services Slider",
    element: GetmiiServiceSlider,
  },
  {
    path: "/getmii-logo",
    name: "Getmii Logo",
    element: GetmiiLogo,
  },
  //SHOP CATEGORY ROUTES
  {
    path: "/shop-category",
    name: "Shop Category",
    element: ShopCategory,
  },
  {
    path: "/shop-category-slider/:id",
    name: "Shop Category Slider",
    element: ShopCategorySlider,
  },
  {
    path: "/shop-category-slider",
    name: "Shop Category Slider",
    element: ShopCategorySlider,
  },
  //PRODUCT CATEGORY ROUTES
  {
    path: "/shop-product-category/:id",
    name: "Shop Product Category",
    element: ProductCategory,
  },
  {
    path: "/shop-product-category",
    name: "Shop Product Category",
    element: ProductCategory,
  },
  {
    path: "/shop-product-category-slider/:id",
    name: "Product Category Slider",
    element: ProductCategorySlider,
  },
  {
    path: "/shop-product-category-slider",
    name: "Product Category Slider",
    element: ProductCategorySlider,
  },
  {
    path: "/shop-product-category-specs/:id",
    name: "Product Category Specification",
    element: ProductCategorySpecs,
  },
  //SHOPS ROUTES
  {
    path: "/registered-shops",
    name: "Registered Shops",
    element: RegisteredShop,
  },
  {
    path: "/shop-details/:id",
    name: "Shop Details",
    element: ShopDetails,
  },
  //PRODUCTS ROUTES
  {
    path: "/products",
    name: "Products",
    element: Products,
  },
  {
    path: "/product/:id",
    name: "Product Details",
    element: ViewProduct,
  },
  {
    path: "/variant-specs/:id",
    name: "Size",
    element: VariantSpecs,
  },
  //ORDER ROUTES
  {
    path: "/orders",
    name: "Orders",
    element: Orders,
  },
  {
    path: "/order-details/:id",
    name: "Order Details",
    element: OrderDetails,
  },
  //CAMPAIGN ROUTES
  {
    path: "/campaign",
    name: "Campaign",
    element: Campaign,
  },
  {
    path: "/campaign-item/:id",
    name: "Campaign Item",
    element: CampaignItem,
  },
  {
    path: "/demonstration-list",
    name: "Demonstration List",
    element: DemoList,
  },
  //DEMAND ROUTES
  {
    path: "/demands",
    name: "Demands",
    element: Demands,
  },
  {
    path: "/demand-details",
    name: "Demands Details",
    element: DemandDetails,
  },
  {
    path: "/vendor-wallet-slider",
    name: "Vendor Wallet Slider",
    element: WalletSlider,
  },
  {
    path: "/user-wallet-slider",
    name: "User Wallet Slider",
    element: UserWalletSlider,
  },
  {
    path: "/city-jobs",
    name: "City Jobs",
    element: Jobs,
  },
  {
    path: "/city-jobs-industries",
    name: "City Jobs",
    element: JobIndustries,
  },
  {
    path: "/job-details/:id",
    name: "Job Details",
    element: JobDetails,
  },
  {
    path: "/subscription-payments",
    name: "Subscription Payments",
    element: SubsciptionPayments,
  },
  {
    path: "/app-notification",
    name: "App Notifications",
    element: Notifications,
  },
  {
    path: "/shop-user-guide",
    name: "Shop User Guide",
    element: UserGuideList,
  },
];

export default routes;
